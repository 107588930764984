/* * {
  font-family: "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans",
  Garamond,serif,
  "Helvetica Neue", Arial, sans-serif;
  } */

input::placeholder {
  font-family: "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans",
    "Helvetica Neue", Arial, sans-serif !important;
}

body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #333; */
  height: 60px;
}

.navbar-logo img {
  width: 100px;
  max-width: 100%;
  height: auto;
}

.descrip-charts {
  font-style: bold;
  font-size: 25px;
  text-align: center;
  font-weight: bold;
}

@media (min-width: 1024px) {
  .navbar-logo img {
    width: 220px;
  }

  .video-player {
    width: 60%;
  }
}

@media (max-width: 1024px) {
  .navbar-logo img {
    width: 90px;
  }

  .video-player {
    width: 75%;
  }
}

@media (max-width: 768px) {
  .navbar-logo img {
    width: 70px;
  }

  .video-player {
    width: 90%;
  }
}

@media (max-width: 480px) {
  .navbar-logo img {
    width: 50px;
  }

  .video-player {
    width: 95%;
  }
}

@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    height: auto;
    padding: 10px 0;
  }

  .navbar-logo {
    text-align: center;
  }

  .img-icon-fluid {
    width: 90px;
  }

  .descrip-charts {
    font-size: 14px;
  }
}

.video-card {
  background-color: white;
  border-radius: 15px;
  box-shadow: 0px 3px 50px #00000029 !important;
  padding: 15px;
  margin: 50px auto;
  max-width: 950px;
  width: 70%;

}

.video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  overflow: hidden;
}

.video-player {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

@media (max-width: 768px) {
  .video-card {
    width: 95%;
    padding: 15px;
  }
}

.center-div {
  margin-top: 100px !important;
}
